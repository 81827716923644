@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'typeface-montserrat';

html {
    font-size: 50%;
    box-sizing: border-box;
    /* font-family: 'Roboto', sans-serif !important; */
}

pre {
    font-family: Montserrat;
    /* font-family: 'Roboto', sans-serif !important; */
}

body {
    padding: 0;
    margin: 0;
}

h1 {
    margin: 0;
    padding: 0;
    font-size: 5rem;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: 4.2rem;
}

h3 {
    margin: 0;
    padding: 0;
    font-size: 3.5rem;
}

h4 {
    margin: 0;
    padding: 0;
    font-size: 2.5rem;
}

h5 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
}

h6 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
}

p {
    margin: 0;
    padding: 0;
    font-size: 3rem;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
  
/* scroll bars */
/* For Webkit browsers (Chrome, Safari, Edge) */
.sidebar1::-webkit-scrollbar {
  width:10px; /* Set the width of the scrollbar */
}

.sidebar1::-webkit-scrollbar-thumb {
  background-color: #E8EAF7; /* Set the color of the thumb */
  border-radius: 6px; /* Set the border radius of the thumb */
}

.sidebar1::-webkit-scrollbar-thumb:hover {
  background-color: #ced2e9; /* Set the color of the thumb on hover */
}

.sidebar1::-webkit-scrollbar-track {
  background-color: #182AB408; /* Set the color of the track */
  border-radius: 6px; /* Set the border radius of the thumb */
}


/* sidebar Poisition Fixing and Responsive */
.layout-wrapper {
    position: relative;
    height: 100vh;
}

.top-header {
    position: fixed;
    width: 100%;
    top: 0;
    background: #FFF;
    z-index: 2;
}

.content-wrapper {
    position: relative;
    min-height: calc(100vh - 77px);
    top: 77px;
}

.sidebar {
    position: fixed;
    width: 300px;
    top: 77px;
    height: calc(100vh - 77px);
    overflow: auto;
    transition: all 0.15s ease-in-out;
    z-index: 1;
    border-right: 1px solid rgba(24, 42, 180, 0.10);
}

.page-content {
    padding-left: 300px;
}

@media (max-width: 1024px) {
    .sidebar {
        left: -300px;
    }

    .page-content {
        padding-left: 0px;
    }

    .sidebar.open {
        left: 0px;
    }
}


.nav-links .active {
    border-radius: 5px;
    background: linear-gradient(274deg, #9836A6 0.82%, #50AFF8 100%);
    color: #FFF;
}

.nav-links .active p {
    color: #FFF;
}

.nav-links .active img {
    filter: brightness(5);
}

.date-wrapper .react-datepicker-wrapper {
    width: 100%;
}

.contact-no-wrapper .react-tel-input .form-control {
    width: calc(100% - 53px);
    border: none;
    height: 46px;
    padding-left: 20px;
    margin-left: 53px;
}

.contact-no-wrapper .react-tel-input .flag-dropdown {
    background-color: rgba(24, 42, 180, 0.03);
    border: none;
    border-radius: 5px !important;
}

.contact-no-wrapper .react-tel-input .selected-flag:hover,
.contact-no-wrapper .react-tel-input .selected-flag:focus {
    background-color: rgba(24, 42, 180, 0.03) !important;
}

.contact-no-wrapper .react-tel-input .selected-flag {
    padding-left: 0px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-no-wrapper .react-tel-input .selected-flag .arrow {
    display: none;
}

.profile-search label {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.radio-btn-wrapper .radio {
    accent-color: auto;
    cursor: pointer;
}

.radio-btn-wrapper input[type="radio"]:checked {
    accent-color: rgba(128, 128, 255, 1);
}

.radio-btn-wrapper input[type="radio"]:hover {
    accent-color: rgba(128, 128, 255, 1);
}

.bg-popupScreen label {
    width: 30%;
}

.file-img-conatiner .img-wrapper {
    width: 40%
}

.file-img-conatiner .input-file {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-img-conatiner input[type="file"] {
    opacity: 1;
    margin: 0;
    padding: 0;
    width: 46px;
    height: 46px;
    left: 45%;
    position: absolute;
    cursor: pointer;
}

.file-img-conatiner input[type="file"]::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    background-color: rgba(24, 42, 180, 0.03);
    background-image: url('./assets/svg/upload_icon.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
}

input[type="file"]::-webkit-file-upload-button {
    background-color: #ffffff00;
    margin: 0px;
    padding: 0px;
    border: 0px;
    color: rgba(24, 42, 180, 0.03);
}


.radio-btn-wrapper label {
    display: flex;
    align-items: center;
}

.radio-btn-wrapper input[type="checkbox"] {
    appearance: none !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    background-clip: content-box !important;
    border: 2px solid rgba(61, 0, 166, 0.24) !important;
    background-color: rgba(61, 0, 166, 0.10) !important;
    flex: 0 0 24px;
}

.radio-btn-wrapper input[type="checkbox"]:checked {
    background-color: #3D00A6 !important;
    padding: 4px !important;
    border: 1px solid rgba(61, 0, 166, 0.24) !important;
}

.access-box-wrapper .access-box:first-child .left {
    border-radius: 12px 0px 0px 0px;
    border: 1px solid rgba(24, 42, 180, 0.20);
    border-bottom: none;
}

.access-box-wrapper .access-box:first-child .right {
    border-radius: 0px 12px 0px 0px;
    border: 1px solid rgba(24, 42, 180, 0.20);
    border-bottom: none;
}

.access-box-wrapper .access-box:last-child .left {
    border-radius: 0px 0px 0px 12px;
    border: 1px solid rgba(24, 42, 180, 0.20);
    border-top: none;
}


.access-box-wrapper .access-box:last-child .right {
    border-radius: 0px 0px 12px 0px;
    border: 1px solid rgba(24, 42, 180, 0.20);
    border-top: none;
}


.black-label label {
    color: #000 !important;
    font-weight: 500 !important;
}

.select-wrapper select {
    padding: 0px 10px;
    color: #666666 !important;
}

.bill-form label {
    width: 300px;
}

.bill-form label~div.flex {
    width: 100%;
}



.thHeading thead th {
    justify-content: flex-start !important;
    text-align: center !important;
}

.border-table tbody {
    border-radius: 12px;
}

.border-table tbody td {
    border-bottom: none;
}

.border-head thead {
    border-radius: 12px 12px 0px 0px;
}

.bottom-radius thead {
    border-radius: 0 0 12px 12px;

}

.input-field-custom input {
    height: auto;
    padding: 5px 10px;
    color: rgba(102, 102, 102, 0.60);
    font-size: 14px;
    font-weight: 400;
}

.transcation-form .input-field-custom:last-child {
    border-radius: 0px 0px 12px 12px;
}

.date-padding-right input {
    padding-right: 30px;
}

.date-padding-right div.absolute {
    right: -10px;
}


.coupons-form label {
    color: #666666 !important;
    max-width: 170px;
    min-width: 170px;
    font-weight: 500;
}

.coupons-form label~div.flex {
    width: 100%;
}

.coupons-form input[type='textarea'],
.coupons-form textarea {
    width: 100%;
}

.coupons-form .delete-btn button {
    width: 50%;
    border-radius: 5px;
    background: #FC0D0D;
    font-size: 16px;
    font-weight: 500;
}

.radio-btn-custom input[type="radio"] {
    appearance: none !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 50% !important;
    background-clip: content-box !important;
    border: 2px solid #C2BAE1 !important;
    background-color: #C2BAE1 !important;
    flex: 0 0 24px;
    padding: 3px;
}

.radio-btn-custom input[type="radio"]:checked {
    background-color: #8080FF !important;
    padding: 3px !important;
    border: 1px solid #8080FF !important;
}

.coupons-form .radio-btn-custom label {
    margin-right: 20px;
}

.coupons-form .radio-btn-custom label {
    min-width: max-content;
}

.coupons-form .v-coupoun-btn-wrapper button {
    width: 100%;
}

.misc-table table,
.misc-table th,
.misc-table td {
    border: 1px solid rgba(24, 42, 180, 0.10);
}

.misc-table {
    width: 100%;
    border-collapse: collapse;
    border-color: rgba(24, 42, 180, 0.10);
}

.misc-table td {
    border: 1px solid rgba(24, 42, 180, 0.10);
    padding: 5px;
    text-align: center;
}

.misc-table thead tr:first-child th:first-child {
    border-radius: 10px 0 0 0;
}

.misc-table thead tr:first-child th:last-child {
    border-radius: 0px 10px 0 0;
}

.purchase-checkbox label {
    color: #666666;
    gap: 6px;
}

.purchase-checkbox .relative {
    flex-wrap: wrap;
}

.purchase-checkbox .w-\[45\%\].text-2xl {
    width: 100%;
}

.coupons-form .file-img-conatiner .img-wrapper {
    width: 85%;
}

.search-date-bolck label {
    max-width: max-content;
    min-width: auto;
    margin-right: 15px;
}


.gradient-border {
    padding: 5px;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, rgba(85, 167, 243, 1) 0%, rgba(145, 66, 174, 1) 100%) border-box;
    border: 5px solid transparent;
    border-image-slice: 1;
    border-radius: 20px;
}

.gradient-dropdowns select {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(274deg, rgba(80, 175, 248, 0.10) 0.82%, rgba(152, 54, 166, 0.10) 100%);
    padding: 0px 10px 0px 25px;
}


.qr-product-etails label {
    width: max-content;
    min-width: 200px;
}


.qr-product-etails div.relative,
.qr-product-etails #dropdown,
.qr-manufacturing-details .relative {
    width: 100%;
}


.qr-manufacturing-details label {
    width: 300px;
}

.qr-manufacturing-details textarea,
.qr-manufacturing-details input {
    border-radius: 5px;
}

.qr-manufacturing-details .file-img-conatiner .img-wrapper {
    width: 100%;
}

.qr-manufacturing-details .file-img-conatiner~label {
    width: 0px;
}


.provide-manufacturing-details label {
    width: max-content;
    min-width: 200px;
}


.provide-manufacturing-details .relative {
    width: 100%;
    gap: 5px;
}

.coupons-view-table .row:last-child {
    border-radius: 0px 0px 10px 10px;
}


.gradient-btn {
    border-radius: 5px;
    background: linear-gradient(274deg, #9836A6 0.82%, #50AFF8 100%);
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0px 20px;
}

.batch-table {
    overflow-x: auto;
}

.batch-table thead {
    width: 100%;
    display: table-row;
    height: auto;
}

.batch-table thead th {
    display: table-cell;
    text-align: center;
    min-width: 130px;
    max-width: fit-content;
    padding: 2px 10px;
    font-size: 16px;
}

.batch-table thead th:last-child {
    min-width: 200px;
}

.batch-table tbody td {
    padding: 10px 0px;
    font-size: 14px;
    text-align: center;
}

.batch-table tbody td:first-child {
    padding-left: 20px;
}

.batch-table tbody td img {
    width: 30px;
    height: auto;
}

.generate-img-upload-wrapper label {
    display: none;
}

.generate-img-upload-wrapper .file-img-conatiner {
    min-width: 250px;
    max-width: 100%;
    padding-right: 70px;
}

.error-text {
    flex: 0 0 100%;
    padding-top: 3px;
}

.small-textbox input {
    height: 46px;
}

.download-qr-code-table {
    overflow-x: auto;
}

.download-qr-code-table thead {
    width: 100%;
    display: table-row;
    height: 100% !important;
}

.download-qr-code-table thead th {
    display: table-cell;
    text-align: center;
    min-width: 130px;
    max-width: fit-content;
    padding: 5px 10px;
    font-size: 16px;
}

.img-upload-wrapper .file-img-conatiner .img-wrapper {
    width: 70%;
}

.img-upload-wrapper label {
    width: fit-content;
}

.dropdown-icon select {
    line-height: 1.5em;
    padding: 11px 30px 11px 10px;

    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.dropdown-icon select {
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 10px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

textarea::placeholder {
    color: #AFAFB1 !important;
}

@media (max-width: 1400px) {

    .table-responsive,
    .misc-table {
        overflow-x: scroll;
    }

    .table-responsive .thHeading,
    .misc-table table {
        max-width: 1100px;
        width: 1100px;
    }

}

@media (max-width: 1200px) {
    .access-box-wrapper {
        overflow-x: scroll;
        max-width: 100%;
    }

    .access-box-wrapper .access-box {
        gap: 10px;
    }

    .access-box-wrapper .access-box .left {
        min-width: 400px;
    }

    .access-box-wrapper .access-box .right {
        min-width: 700px;
    }

    .qr-product-etails label {
        min-width: 150px;
    }
}

@media (max-width: 767px) {
    .table-responsive .thHeading {
        max-width: 992px;
        width: 992px;
    }

    .qr-manufacturing-details label {
        width: 100%;
        min-width: 100%;
    }

    .qr-manufacturing-details textarea,
    .qr-manufacturing-details input {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .generate-img-upload-wrapper .flex.md\:justify-end {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .generate-img-upload-wrapper .file-img-conatiner {
        width: 100%;
    }
    
}